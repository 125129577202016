






























































































































import Vue from "vue";
import TSelect from "@/components/core/TSelect.vue";

export default Vue.extend({
  name: "MeiaEntrada",

  components: {
    TSelect,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data: () => ({
    defaultSelected: {
      title: "São Paulo (SP)",
      body: "Diretores, coordenadores pedagógicos, supervisores e titulares do quadro de apoio de escolar estadual e municipal (Lei Estadual SP 15.298/14) e Professores da rede pública estadual e municipal (Lei Estadual SP 14.729/2012). É necessário apresentar documento de identidade oficial com foto e carteira funcional da Secretaria de Educação ou Holerite que comprove a condição.",
    },
    items: [
      {
        title: "São Paulo (SP)",
        body: "Diretores, coordenadores pedagógicos, supervisores e titulares do quadro de apoio de escolar estadual e municipal (Lei Estadual SP 15.298/14) e Professores da rede pública estadual e municipal (Lei Estadual SP 14.729/2012). É necessário apresentar documento de identidade oficial com foto e carteira funcional da Secretaria de Educação ou Holerite que comprove a condição.",
      },
      {
        title: "Rio de Janeiro (RJ)",
        body: "Os menores de 21 anos têm direito à meia-entrada, de acordo com a Lei Estadual RJ nº 3.364/2000.<br /><br />Rio de Janeiro (cidade): Os professores e profissionais da rede pública municipal de ensino têm direito à meia-entrada de acordo com a Lei Municipal do Rio de Janeiro nº 3.424/2002, mediante apresentação da carteira funcional emitida pela Secretaria Municipal de Educação. Além disso, de acordo com a PL 114/2021, estabelece-se a meia-entrada para professores de cursos preparatórios comunitários ou sociais em estabelecimentos de lazer, entretenimento e difusão cultural. Cobrado 50% do valor em estabelecimentos que realizem espetáculos musicais, circenses, teatrais, cinematográficos, de artes plásticas e artísticos em geral. Para comprovação a prefeitura emitirá um atestado da condição de professor de curso preparatório comunitário/social. Emitido e disponibilizado pela internet, o atestado será criado a partir da declaração de serviço prestado às organizações de ensino cadastradas junto à Prefeitura.",
      },
      {
        title: "Minas Gerais (MG)",
        body: "Estudantes regularmente matriculados em estabelecimentos de ensino de 1º, 2º e 3º graus em casas de diversão, de espetáculos teatrais, musicais e circenses, em casas de exibição cinematográfica, em praças esportivas e similares das áreas de esporte, cultura e lazer do Estado de Minas Gerais (Lei Estadual 11052, de 24/03/1993). É necessário apresentar documento do respectivo estabelecimento de ensino e emitida pela União Nacional dos Estudantes (UNE), União Brasileira dos Estudantes Secundaristas (Ubes) ou União Colegial de Minas Gerais (UCMG) e distribuída pelas respectivas entidades filiadas, tais como União Estadual dos Estudantes, uniões municipais, diretórios centrais de estudantes, diretórios acadêmicos, centros acadêmicos e grêmios estudantis.<br /><br />Belo Horizonte: Os menores de 21 anos têm direito à meia-entrada, de acordo com a Lei Municipal de Belo Horizonte 9.070/2005.",
      },
      {
        title: "Rio Grande do Sul (RS)",
        body: "Doadores regulares de sangue, desde que registrados no hemocentro e nos bancos de sangue dos hospitais do Estado, de acordo com a Lei Estadual do Rio Grande do Sul  nº 13.891/2012, apresentando documento de identidade oficial com foto e carteira de controle das doações expedida pela Secretaria de Estado da Saúde ou pelos hemocentros e bancos de sangue do Estado do Rio Grande do Sul, dentro do prazo de validade.<br /><br />Porto Alegre: Os menores de 15 anos têm direito à meia-entrada, de acordo com as Leis Municipais de Porto Alegre 9.989/2006 e 11.211/2012. Aposentados ou pensionistas do INSS, desde que recebam até três salários mínimos, de acordo com a Lei Municipal de Porto Alegre 7.366/1993, devem apresentar documento fornecido pela Federação dos Aposentados e Pensionistas do Estado do Rio Grande do Sul ou outras Associações da Classe, devidamente registradas ou filiadas à citada Federação.",
      },
      {
        title: "Goiás (GO)",
        body: "Os professores e profissionais da rede pública municipal e estadual de ensino têm direito à meia-entrada de acordo com a Lei Estadual de Goiás nº 14.975/2004, 17.396/2011 e 17.575/2012, mediante apresentação de documento de identidade oficial com foto e comprovante de vínculo de emprego com a instituição de ensino.<br /><br />Goiânia: Doadores regulares de sangue, desde que registrados perante a Secretaria Municipal de Saúde ou banco de sangue, de acordo com a Lei Municipal nº 8.558/2007, apresentando documento de identidade oficial com foto e documento oficial expedido pela Secretaria de Estado da Saúde ou banco de sangue, válido e vigente.",
      },
      {
        title: "Brasília (DF)",
        body: "Assegura aos profissionais da saúde, do sistema público e privado de saúde, o pagamento da metade do valor cobrado para aquisição de ingressos em eventos artísticos, culturais, cinematográficos e desportivos. OA meia entrada será aplicada ainda que no valor do ingresso já esteja sendo aplicado desconto ou preço promocional. Para comprovação o profissional da área de saúde deve apresentar documento de identidade e, alternativamente, contracheque, carteira funcional emitida por estabelecimento público ou privado de saúde ou carteira de identificação expedida por entidades de classe.",
      },
      {
        title: "Pernambuco (PE)",
        body: "Professores e servidores (ativos e aposentados), vinculados a instituições de ensino publicamente reconhecidos no Estado de Pernambuco têm direito à meia-entrada de acordo com a Lei Estadual 12.258, de 22 de agosto de 2002, mediante apresentação obrigatória de carteira funcional emitida pela Secretaria Estadual de Educação, Carteira Profissional, documento de comprovação de filiação a instituição representativa de professores ou servidores de instituições de ensino ou qualquer outro documento público que comprove o preenchimento dos requisitos previstos na Lei, além de Documento de Identidade oficial com foto, expedido por órgão público e válido em todo território nacional, original ou cópia autenticada.<br /><br />Os portadores de câncer e seu acompanhante (quando comprovada a necessidade de acompanhamento) têm direito a meia-entrada, conforme Lei Estadual 15.724/2016, mediante apresentação obrigatória do atestado médico contendo a classificação internacional da doença (CID) fornecido por um profissional cadastrado no Sistema Único de Saúde (SUS), expedido em até um ano antes de sua apresentação e Documento de Identidade oficial com foto, expedido por órgão público e válido em todo território nacional.<br /><br />Recife: Os professores da rede municipal de ensino têm direito à meia-entrada de acordo com a Lei Municipal de Recife nº 16.902/2003, mediante apresentação de documento de identidade oficial com foto e holerite.",
      },
      {
        title: "Maranhão (MA)",
        body: "Concede gratuidade de entrada a portadores de Síndrome de Down em estádios, ginásios esportivos, parques aquáticos e demais estabelecimentos que forneçam serviços de entretenimento e acesso à cultura, esporte e lazer. Os administradores e/ou responsáveis pelos estabelecimentos devem promover o credenciamento e a expedição de passes e/ou passaportes especiais para os beneficiários.",
      },
    ],
  }),
  methods: {
    mudaEstado(selected: { title: string; body: string }) {
      this.defaultSelected.title = selected.title;
      this.defaultSelected.body = selected.body;
    },
  },
});
